import { IComponentController } from 'angular';
import { SubscriptionService } from '@client/src/global/services/subscription/subscription.service';
import { ChangePlanService } from '@client/src/global/services/change-plan/change-plan.service';
import {
  DefinedSubscriptionPlanId,
  FeatureKey,
  FlexibleSubscriptionPlanId,
} from '@client/data/subscription';
import { toastError } from '@client/core/components/react/Toastify';
import template from './change-plan.html?raw';

export interface IDataType {
  triggerSource?: string;
  suggestedPlanId?: DefinedSubscriptionPlanId | FlexibleSubscriptionPlanId;
  showModal: {
    enterpriseCall: boolean;
    upgrade: boolean;
  };
}

class ChangePlan implements IComponentController {
  data: IDataType = {
    showModal: {
      enterpriseCall: false,
      upgrade: false,
    },
  };

  static $inject = ['$scope', '$translate', 'SubscriptionService', 'ChangePlanService'];

  constructor(
    private $scope: ng.IScope,
    private $translate: angular.translate.ITranslateService,
    private SubscriptionService: SubscriptionService,
    private ChangePlanIntegrationSharedService: ChangePlanService
  ) {
    this.SubscriptionService = SubscriptionService;
    this.currentSubscription = this.currentSubscription.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.cleanData = this.cleanData.bind(this);
    this.showChangePlanModal = this.showChangePlanModal.bind(this);
    this.ChangePlanIntegrationSharedService.setComponentListener(this);
  }

  currentSubscription() {
    return !!this.SubscriptionService.currentSubscription;
  }

  cleanData() {
    this.data = {
      showModal: {
        enterpriseCall: false,
        upgrade: false,
      },
    };
  }

  closeModal() {
    this.cleanData();
  }

  showChangePlanModal({
    isUnavailableToEfulAccount,
    triggerSource,
    featureKey,
    suggestedPlanId,
  }: {
    isUnavailableToEfulAccount?: boolean;
    triggerSource?: string;
    featureKey: FeatureKey;
    suggestedPlanId?: DefinedSubscriptionPlanId | FlexibleSubscriptionPlanId;
  }) {
    if (isUnavailableToEfulAccount) {
      toastError(this.$translate.instant('shipment-info.pay-on-scan.eful-error'));
      return;
    }

    const isEnterprisePlan =
      this.SubscriptionService.currentSubscription?.plan?.id ===
      FlexibleSubscriptionPlanId.Enterprise;

    if (isEnterprisePlan) {
      this.data = {
        triggerSource,
        showModal: {
          upgrade: false,
          enterpriseCall: true,
        },
      };
    } else {
      const planId =
        suggestedPlanId ?? this.SubscriptionService.getSuggestedPlanIdByFeatureKey(featureKey);
      this.data = {
        triggerSource,
        suggestedPlanId: planId,
        showModal: {
          upgrade: true,
          enterpriseCall: false,
        },
      };
    }
    this.$scope?.$apply();
  }
}

const ChangePlanComponent: ng.IComponentOptions = {
  controller: ChangePlan,
  template,
};

export { ChangePlanComponent };
