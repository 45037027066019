import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'easyship-components';
import { Exclaim } from 'easyship-components/icons';
import { useUserSession } from '@/providers//UserSessionProvider';
import { toastError } from '@/components/Toastify';
import { openModal } from '@/utils/modals';
import { ICompanyService } from 'typings/company';
import { openVerificationRequiredModal } from './components/VerificationRequiredModal';
import { VerificationRequiredModalProps } from './types';

const verificationStateMap = {
  requested: 'requested',
  processing: 'processing',
  unverified: 'unverified',
};

const DOCUMENT_LINK =
  'https://support.easyship.com/hc/en-us/articles/21589036468242-Identity-Verification-KYC';
const SUPPORT_LINK = 'https://app.easyship.com/help';
const SESSION_STORAGE_KEY = 'hasVerificationModalShown';

export default function VerificationRequiredBanner({ $injector }: VerificationRequiredModalProps) {
  const CompanyService = $injector.get('CompanyService') as ICompanyService;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const StripeService = $injector.get('StripeService') as any;

  const [isStripeInitiated, setIsStripeInitiated] = useState(false);

  const modalRef = useRef<ReturnType<typeof openModal>>();
  const bannerRef = useRef<HTMLDivElement>(null);

  const userSession = useUserSession();
  const { identity_verification_state: identityVerificationState } = userSession.company;
  const { formatMessage } = useIntl();

  const isShowBanner =
    identityVerificationState &&
    [
      verificationStateMap.requested,
      verificationStateMap.processing,
      verificationStateMap.unverified,
    ].includes(identityVerificationState);

  const isShowButton =
    identityVerificationState &&
    [verificationStateMap.requested, verificationStateMap.unverified].includes(
      identityVerificationState
    );

  const handleVerifyClick = async () => {
    // fetch a client secret and use the Stripe instance to start the verification process
    setIsStripeInitiated(true);

    try {
      const session = await CompanyService.createIdentitiyVerificationSession();
      const stripe = await StripeService.getInstance();
      await stripe.verifyIdentity(session.client_secret);

      modalRef.current?.close();
    } catch (err) {
      toastError(err.data?.errors?.join(',') || formatMessage({ id: 'toast.default-error' }));
    } finally {
      setIsStripeInitiated(false);
    }
  };

  const handleCloseModal = () => {
    setIsStripeInitiated(false);
  };

  const handleContactClick = () => {
    window.open(SUPPORT_LINK, '_blank');
  };

  useEffect(() => {
    if (isShowBanner) {
      document.body.classList.add('show-verification-required-banner');

      const hasModalShown = window.sessionStorage.getItem(SESSION_STORAGE_KEY);

      if (!hasModalShown) {
        modalRef.current = openVerificationRequiredModal({
          handleVerify: handleVerifyClick,
          handleClose: handleCloseModal,
        });

        window.sessionStorage.setItem(SESSION_STORAGE_KEY, 'true');
      }

      setTimeout(() => {
        if (document.body.classList.contains('show-trial-expiry')) {
          bannerRef.current?.classList.add('mt-[50px]');
        }
      }, 1000);
    }
  });

  if (!isShowBanner) {
    return null;
  }

  return (
    <div
      ref={bannerRef}
      className="fixed top-0 flex items-center w-full z-[26] pt-[15px] pr-0 pb-[15px] pl-[210px] bg-red-100"
    >
      <div className="flex items-center justify-center gap-3">
        <Exclaim className="w-[20px] h-[20px] text-red-900" />
        <p className="mb-0 text-ink-900 text-base">
          <FormattedMessage
            id={`identity-verification.required-banner.description.${identityVerificationState}`}
          />
          &nbsp;
          <a href={DOCUMENT_LINK} target="_blank" rel="noreferrer" aria-label="support document">
            <FormattedMessage id="global.learn-more" />
          </a>
        </p>
        {isShowButton && (
          <Button
            loading={isStripeInitiated}
            onClick={
              identityVerificationState === verificationStateMap.requested
                ? handleVerifyClick
                : handleContactClick
            }
          >
            <FormattedMessage
              id={
                identityVerificationState === verificationStateMap.requested
                  ? 'identity-verification.required-banner.verify-now'
                  : 'global.contact-support'
              }
            />
          </Button>
        )}
      </div>
    </div>
  );
}
